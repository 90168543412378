import React from "react";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import ThankYou from "../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
import Articles from "../../components/Articles/Articles";
import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../data/restaurant-data";

const ArticlesHeader = {
  title: "Interesting Reads",
  bgText: "",
};

export default function ThankYouPage() {
  return (
    <LpLayout>
      <SEO title="Thank you - your request for a gated asset has been received | SpotOn " />

      <ThankYou
        phoneNumber=""
        phoneText=""
        thankYouMessage="Someone from our team will be in touch shortly"
        thankYouSubheader=""
      />
      <Articles
        sectionData={ArticlesHeader}
        blogFilters={{
          limit: 6,
          include: "tags,authors",
          filter: "tag:press-releases",
        }}
        cornerStone={false}
        style={{ marginTop: 60, marginBottom: 60 }}
      />
      <TestmonialReviews sectionData={restaurantTestimonials} />
    </LpLayout>
  );
}
